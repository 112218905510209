import type { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData } from '../../../api/front/index';
import type { IEventRequests, IEventRequestManager } from '../../../services/apiaccessprovider.definition';

export function sessionPersonDatasouce(requestManager: IEventRequestManager) {
    return import("../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.ISessionPerson>(requestManager, "person/session", "SessionPerson");
    });
}

export function registerToSession(eventRequests: IEventRequests, sessionid: string) {
    return eventRequests.apiFront.postEventJson<any>(`person/session/${sessionid}/register`, '', defaultPostHeaderForJsonData);
}

export function unregisterToSession(eventRequests: IEventRequests, sessionid: string) {
    return eventRequests.apiFront.postEventJson(`person/session/${sessionid}/unregister`, '', defaultPostHeaderForJsonData);
}

export function bookmarkSession(eventRequests: IEventRequests, sessionid: string) {
    return eventRequests.apiFront.postEventJson<any>(`person/session/${sessionid}/bookmark`, '', defaultPostHeaderForJsonData);
}
