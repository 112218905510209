import * as moment from 'moment';
import { combinePredicate } from '@inwink/utils/methods/combinepredicate';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { Entities } from '@inwink/entities/entities';
import { logging } from '@inwink/logging';
import * as momenttimezone from 'moment-timezone';
import { surveysModule } from '@@event/features';
import { actions as userActions } from '@@services/userservice';
import { SortAndFilterItems } from '@@pages/teaseritems.props';
import { States } from '@@services/services';
import { getTimeZoneDate } from '@@components/displaytimezonedate';
import { bookmark, register, registerBatch, unregister } from './actionqueue.session';
import { getEntityPredicate } from '../../../../data/entityfilters';
import { userMessageModule } from '../../../../routes/appmodules';
import { registerToSession, unregisterToSession } from '../../api/sessionperson';
import { actionQueue } from '../../../../actionsqueue';

const logger = logging.getLogger("Data");

function tracking() {
    return import("../../../../services/tracking/actions");
}

export function computeSessionDates(session: Entities.ISession, eventDetail: Entities.IEventDetail, i18n: States.i18nState) {
    if (session) {
        computeStartDate(session, eventDetail, i18n);
        computeEndDate(session, eventDetail, i18n);
    }
}

function computeEndDate(session: Entities.ISession, eventDetail: Entities.IEventDetail, i18n: States.i18nState) {
    if (!session) {
        return;
    }
    const ss = session;
    let s = session.$endDate;

    if (!s || typeof s === "string") {
        const slot = session.timeslots && session.timeslots.length ? session.timeslots[0] : null;
        if (slot && slot.endDate) {
            s = getTimeZoneDate(moment(slot.endDate).toISOString(true).split('.')[0], eventDetail, i18n);
        } else {
            s = null;
        }
        ss.$endDate = s;
    }
}

function computeStartDate(session: Entities.ISession, eventDetail: Entities.IEventDetail, i18n: States.i18nState) {
    if (!session) {
        return;
    }
    const ss = session;
    let s = session.$startDate;

    if (!s || typeof s === "string") {
        const slot = session.timeslots && session.timeslots.length ? session.timeslots[0] : null;
        if (slot && slot.startDate) {
            s = getTimeZoneDate(slot.startDate, eventDetail, i18n);
        } else {
            s = null;
        }
        ss.$startDate = s;
    }
}

export function isAllowed(session: Entities.ISession, user: Entities.IAppUser) {
    if (!session) {
        return false;
    }
    if (!session.forKinds) return true;
    if (!(session.forKinds.length)) return true;

    if (user && user.kinds && user.kinds.length) {
        return user.kinds.some((k) => session.forKinds.indexOf(k) >= 0);
    }

    return false;
}

export function isComingSessionPredicate(eventEndDate: string | Date, timeZone: string,
    eventDetail: Entities.IEventDetail, i18n: States.i18nState, testDate?: string) {
    const dt = testDate ? moment(testDate) : moment();

    if (eventEndDate) {
        const eed = timeZone ? momenttimezone.tz(eventEndDate, timeZone) : moment(eventEndDate);
        if (dt > eed) {
            // si la date de comparaison est après la date de l'event, alors on affiche toutes les sessions
            return () => true;
        }
    }

    return (a: Entities.ISession) => {
        computeEndDate(a, eventDetail, i18n);
        if (!a?.$endDate) {
            return true;
        }

        return a.$endDate > dt;
    };
}

export function sortSessionsByDate(eventDetail: Entities.IEventDetail, i18n: States.i18nState, shorterSessionsFirst?: boolean) {
    return (a: Entities.ISession, b: Entities.ISession) => {
        computeStartDate(a, eventDetail, i18n);
        computeStartDate(b, eventDetail, i18n);

        const slotA = a.timeslots && a.timeslots.length ? a.timeslots[0] : null;
        const slotB = b.timeslots && b.timeslots.length ? b.timeslots[0] : null;

        const titleSort = () => {
            const aTitle = a.title && a.title[i18n.currentLanguageCode];
            const bTitle = b.title && b.title[i18n.currentLanguageCode];
            if (aTitle && bTitle) return aTitle.localeCompare(bTitle);

            return 0;
        };

        if (slotA && !slotB) {
            return -3;
        } if (slotB && !slotA) {
            return 3;
        } if (slotA && slotB) {
            const slotAStart = a?.$startDate ?? 0;
            const slotBStart = b?.$startDate ?? 0;

            const diff = (slotAStart as any) - (slotBStart as any);
            if (diff === 0) {
                if (shorterSessionsFirst) {
                    const slotAEnd = a?.$endDate ?? 0;
                    const slotBEnd = b?.$endDate ?? 0;
                    const endDiff = (slotAEnd as any) - (slotBEnd as any);
                    if (endDiff === 0) {
                        return titleSort();
                    }
                    return endDiff;
                }
                return titleSort();
            }
            return diff;
        }

        return titleSort();
    };
}

export function getComingSessions(i18n: States.i18nState, user: States.IAppUserState, event: States.IEventState,
    sessions: Entities.ISession[], showAllSessions?: boolean, testDate?: string): Entities.ISession[] {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const predicate = showAllSessions ? (session: Entities.ISession) => true : isComingSessionPredicate(event.detail.endDate,
        event.detail.timeZone, event && event.detail, i18n, testDate);
    const asArray = sessions.filter((s) => {
        if (isAllowed(s, user && user.currentUser && user.currentUser.detail)) {
            return predicate(s);
        }

        return false;
    });
    asArray.sort(sortSessionsByDate(event && event.detail, i18n));
    return asArray;
}

export function getAvailableSessions(i18n: States.i18nState, user: States.IAppUserState, event: States.IEventState,
    sessions: Entities.ISession[]): Entities.ISession[] {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const predicate = (session: Entities.ISession) => !session.quota || session?.infos?.registered < session.quota;
    const asArray = sessions.filter((s) => {
        if (isAllowed(s, user && user.currentUser && user.currentUser.detail)) {
            return predicate(s);
        }

        return false;
    });
    asArray.sort(sortSessionsByDate(event && event.detail, i18n));
    return asArray;
}

export function getThemeSessions(i18n: States.i18nState, user: States.IAppUserState, themeid: string,
    eventdata: States.IEventDataStore, showOnlyComingSessions = false, testDate?: string): Entities.ISession[] {
    let allsessions = eventdata.sessions.data;
    const eventDetail = eventdata.eventDetail.data[0];
    if (showOnlyComingSessions) {
        allsessions = allsessions.filter(isComingSessionPredicate(eventDetail && eventDetail.endDate,
            eventDetail && eventDetail.timeZone, eventDetail, i18n, testDate));
    }

    const filter = combinePredicate<Entities.ISession>((s) => {
        if (isAllowed(s, user && user.currentUser && user.currentUser.detail)) {
            const hasTheme = s.eventThemes.some((e) => e.id === themeid);
            return hasTheme;
        }
        return false;
    }, () => true);

    const res = allsessions.filter(filter);
    res.sort(sortSessionsByDate(eventDetail, i18n));
    return res;
}

export function getJourneySessions(i18n: States.i18nState, user: States.IAppUserState, journeyid: string,
    eventdata: States.IEventDataStore, showOnlyComingSessions = false, testDate?: string): Entities.ISession[] {
    let allsessions = eventdata.sessions.data;
    const eventDetail = eventdata.eventDetail.data[0];
    if (showOnlyComingSessions) {
        allsessions = allsessions.filter(isComingSessionPredicate(eventDetail && eventDetail.endDate,
            eventDetail && eventDetail.timeZone, eventDetail, i18n, testDate));
    }

    const filter = combinePredicate<Entities.ISession>((s) => {
        if (isAllowed(s, user && user.currentUser && user.currentUser.detail)) {
            const hasJourney = s.journeys?.some((e) => e.id === journeyid);
            return hasJourney;
        }
        return false;
    }, () => true);

    const res = allsessions.filter(filter);
    res.sort(sortSessionsByDate(eventDetail, i18n));
    return res;
}

export function getSessionsByIds(i18n: States.i18nState, user: States.IAppUserState, sessionsids: { id: string }[],
    eventdata: States.IEventDataStore, showOnlyComingSessions = false, testDate?: string): Entities.ISession[] {
    const res = [];
    let allsessions = eventdata.sessions.data;
    const eventDetail = eventdata.eventDetail.data[0];
    if (showOnlyComingSessions) {
        allsessions = allsessions.filter(isComingSessionPredicate(eventDetail && eventDetail.endDate,
            eventDetail && eventDetail.timeZone, eventDetail, i18n, testDate));
    }
    if (sessionsids && sessionsids.length) {
        sessionsids.forEach((s) => {
            const session = allsessions.filter((ss) => {
                return s.id === ss.id && isAllowed(ss, user && user.currentUser && user.currentUser.detail);
            })[0];
            if (session) res.push(session);
        });
    }
    let result = [...res];

    result = result.sort(sortSessionsByDate(eventDetail, i18n));

    return result;
}

export function getRecommendationPredicate(user: States.IAppUserState,
    filter: any, isTeaser?: boolean): (session: Entities.ISession) => boolean {
    let recommendationPredicate: (session: Entities.ISession) => boolean = () => true;
    if (user?.checked && (filter?.my_recommendations || isTeaser)) {
        const favoriteEventThemes = user?.currentUser?.data?.favoriteEventThemes?.data;
        const favoriteEventThemeIds: string[] = favoriteEventThemes?.map((et) => et.eventThemeId)
            || [];

        recommendationPredicate = (session) => session.eventThemes?.length && session.eventThemes
            .some((e) => favoriteEventThemeIds.indexOf(e.id) > -1);
    }

    return recommendationPredicate;
}

function getRegisteredSessionPredicate(user: States.IAppUserState, filter: any): (session: Entities.ISession) => boolean {
    let favoritePredicate: (session: Entities.ISession) => boolean = () => true;
    if (user?.checked && filter?.bookmarked) {
        const registeredSessions = user?.currentUser?.data?.registeredSessions?.data
            ?.filter((registeredSession) => (registeredSession.hasParticipated === true
                || registeredSession.registrationDate) && registeredSession.status === "Active");
        const registeredSessionIds: string[] = registeredSessions?.map((fs) => fs.sessionId)
            || [];

        favoritePredicate = (session) => registeredSessionIds
            .some((f) => f === session.id);
    }

    return favoritePredicate;
}

export function filterSessions(user: States.IAppUserState, i18n: States.i18nState, eventdata: States.IEventDataStore, filter: any,
    fieldtemplate: Entities.IFieldTemplate, searchable: Entities.IFilterTemplate, properties: any): Entities.ISession[] {
    const predicate = getEntityPredicate<Entities.ISession>(filter, fieldtemplate, searchable);
    const recommendationPredicate = getRecommendationPredicate(user, filter);
    const registeredSessionPredicate = getRegisteredSessionPredicate(user, filter);

    const res = eventdata.sessions.data.filter((s) => {
        if (isAllowed(s, user && user.currentUser && user.currentUser.detail)) {
            return predicate(s) && recommendationPredicate(s) && registeredSessionPredicate(s);
        }

        return false;
    });
    res.sort(sortSessionsByDate(eventdata?.eventDetail?.data && eventdata.eventDetail.data[0], i18n));
    return SortAndFilterItems(user, i18n, res, properties, false, null, fieldtemplate?.workTemplate);
}

export function getSessionsContainsSameThemes(session: Entities.ISession, eventdata: States.IEventDataStore,
    i18n: States.i18nState, showOnlyComingSessions = false, testDate?: string): Entities.ISession[] {
    let allsessions = eventdata.sessions.data.filter((s) => s.id !== session.id);

    if (showOnlyComingSessions) {
        const eventDetail = eventdata.eventDetail.data[0];
        allsessions = allsessions.filter(isComingSessionPredicate(eventDetail && eventDetail.endDate,
            eventDetail && eventDetail.timeZone, eventDetail, i18n, testDate));
    }

    if (session.eventThemes && session.eventThemes.length) {
        let allFilteredSessions: Entities.ISession[] = [];
        session.eventThemes.forEach((theme) => {
            const filteredSessions = allsessions.filter((s) => {
                if (s.eventThemes
                    && s.eventThemes.length
                    && s.eventThemes.some((t) => {
                        if (t.id === theme.id) {
                            return true;
                        }
                        return false;
                    })) {
                    return s;
                }

                return null;
            });
            allFilteredSessions = allFilteredSessions.concat(filteredSessions);
        });

        allFilteredSessions = allFilteredSessions.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        if (allFilteredSessions.length) {
            return allFilteredSessions;
        }
        return allsessions;
    }

    return allsessions;
}

export function getSessionsContainsSameRoom(room: Entities.IRoom, eventdata: States.IEventDataStore,
    i18n: States.i18nState, showOnlyComingSessions = false, testDate?: string): Entities.ISession[] {
    let allsessions = eventdata.sessions.data;
    const eventDetail = eventdata.eventDetail.data[0];
    if (showOnlyComingSessions) {
        allsessions = allsessions.filter(isComingSessionPredicate(eventDetail && eventDetail.endDate,
            eventDetail && eventDetail.timeZone, eventDetail, i18n, testDate));
    }

    if (room?.id) {
        let allFilteredSessions: Entities.ISession[] = [];
        const filteredSessions = allsessions.filter((s) => {
            if (s.timeslots
                && s.timeslots.length
                && s.timeslots.some((t) => {
                    if (t?.room?.id === room?.id) {
                        return true;
                    }
                    return false;
                })) {
                return s;
            }

            return null;
        });
        allFilteredSessions = allFilteredSessions.concat(filteredSessions);
        allFilteredSessions = allFilteredSessions.sort(sortSessionsByDate(eventDetail, i18n));
        return allFilteredSessions;
    }

    return allsessions;
}

export function getSessionSurvey(eventdata: States.IEventDataStore, session: Entities.ISession, i18n: States.i18nState) {
    const allsurveys = eventdata?.surveys?.data;
    let surveyDefinition: Entities.ISurvey;

    if (session.surveyId) {
        surveyDefinition = allsurveys.filter((s) => {
            return s.id === session.surveyId;
        })[0];
    }

    if (!surveyDefinition) {
        surveyDefinition = allsurveys?.filter((s) => {
            return s.scope === "Session" && s.isDefault;
        })[0];
    }

    if (InWinkPreview && surveyDefinition) {
        return surveyDefinition;
    }
    if (session.timeslots && session.timeslots.length) {
        let dt;
        if (i18n) {
            dt = moment(getTimeZoneDate(session.timeslots[0].startDate, eventdata.eventDetail.data[0], i18n));
        } else {
            dt = moment(session.timeslots[0].startDate);
        }

        if (moment() >= dt) {
            return surveyDefinition;
        }
    }
}

export function getSessionSpeakers(session: Entities.ISession, eventdata: States.IEventDataStore): Entities.ISpeaker[] {
    const res = [];

    if (session && session.speakers) {
        session.speakers.forEach((s) => {
            const speaker = eventdata.speakers.data.find((sp) => sp.id === s.id);
            if (speaker) {
                res.push(speaker);
            }
        });
    }

    return res;
}

export const sessionActions = {
    register(
        i18n: Entities.i18nHelper,
        elt: HTMLElement,
        session: Entities.ISession
    ): (dispatch, getState: () => States.IAppState) => Promise<boolean> {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            const currentUser = currentstate?.user?.currentUser?.detail;
            if (!currentstate.user.currentUser) {
                userMessageModule().then((mod) => {
                    mod.loginActions.notifyRequireLogin(elt, i18n, session?.id)(dispatch, getState);
                });
                return Promise.resolve(true);
            }
            if (!currentUser?.id || !currentUser?.isRegistered) {
                userMessageModule().then((mod) => {
                    mod.messageRegistrationActions.notifyRequireRegistration(elt, i18n, session?.id)(dispatch, getState);
                });
                return Promise.resolve(true);
            }
            if (session?.forKinds?.length && 
                !currentUser?.kinds?.some?.(k => session?.forKinds?.includes?.(k))) {
                userMessageModule().then((mod) => {
                    mod.messageRegistrationActions.notifyRequirePersonKinds(elt, i18n)(dispatch);
                });
                return Promise.resolve(true);
            }

            const isModerator = checkIfModerator(currentstate.user, session);
            const userId = currentstate.user?.currentUser?.detail?.id;
            const isSpeaker = currentstate.event
                .data?.speakers.data?.some((s) => s.id === userId);
            let survey;
            let answer;
            if (session.registrationSurveyId) {
                survey = currentstate.event.data?.surveys.data.find((s) => s.id === session.registrationSurveyId);
            } else {
                survey = currentstate.event.data?.surveys
                    .data.find((s) => s.scope === 'SessionRegistration' && s.isDefault === true);
            }
            if (survey) {
                const answerid = ('survey#' + userId + '#' + survey.id
                    + '#session#' + session.id).toLowerCase();
                answer = localStorage.getItem(answerid);
            }

            const doRegistration = () => {
                if (session.quota || session.sessionPersonQuotas?.length) {
                    if (!session.infos || !session.infos.registered || !session.quota
                        || session.infos.registered < session.quota) {
                        return registerToSession(currentstate.event.requestManagers, session.id).then((res) => {
                            if (res && res.code) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject({ code: res.code });
                            }
                            const existing = currentstate.user.currentUser.data.registeredSessions
                                .data.filter((rs) => rs.sessionId === session.id
                                    && rs.personId === currentstate.user.currentUser.data.userId)[0];
                            if (existing) {
                                existing.status = "Active";
                                existing.registrationDate = new Date();
                                currentstate.user.currentUser.data.registeredSessions.update(existing);
                            } else {
                                const sp: Entities.ISessionPerson = {
                                    id: undefined,
                                    sessionId: session.id,
                                    personId: currentstate.user.currentUser.data.userId,
                                    status: "Active",
                                    isRegisted: true,
                                    registrationDate: new Date(),
                                    hasBookmarked: false,
                                    hasParticipated: false
                                };
                                currentstate.user.currentUser.data.registeredSessions.insert(sp);
                            }

                            currentstate.user.currentUser.data.save().then(() => {
                                userActions.dataChanged()(dispatch);
                                return true;
                            });
                        }, () => {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject({ code: -1 });
                        });
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject({ code: -1 });
                }

                return sessionActionQueue.register(session.id)(dispatch, getState).then(() => true);
            };

            if (survey && !isModerator && !isSpeaker && !answer) {
                return new Promise((resolve, reject) => {
                    surveysModule(dispatch, getState).then((mod) => {
                        mod.surveysActions.showSurveyAction(
                            i18n,
                            survey.title,
                            survey, "Session",
                            session.id,
                            () => {
                                doRegistration().then(() => resolve(true), (er) => reject(er));
                            },
                            () => {
                                resolve(false);
                            }
                        )(dispatch, getState);
                    });
                });
            }
            return doRegistration();
        };
    },

    unregister(elt: HTMLElement, session: Entities.ISession): (dispatch, getState: () => States.IAppState) => Promise<void> {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            if (currentstate.user.currentUser.data) {
                const sp = currentstate.user.currentUser.data.registeredSessions.data
                    .find((rs) => rs.sessionId === session.id && rs.personId === currentstate.user.currentUser.data.userId);
                if (sp?.participationDate) {
                    logger.info("it is forbidden to unsubscribe from a session that has been attended");
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(false);
                }
                if (session.quota) {
                    return unregisterToSession(currentstate.event.requestManagers, session.id).then(() => {
                        currentstate.user.currentUser.data.registeredSessions.remove(sp);
                        return currentstate.user.currentUser.data.save().then(() => {
                            userActions.dataChanged()(dispatch);
                            return true;
                        });
                    }, (err) => {
                        console.log(err);
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject(false);
                    });
                }
                return sessionActionQueue.unregister(session.id)(dispatch, getState).then(() => true);
            }
            logger.info("no user data initialized");
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject("no user data initialized");
        };
    },

    bookmark(
        i18n: Entities.i18nHelper,
        elt: HTMLElement,
        session: Entities.ISession
    ): (dispatch, getState: () => States.IAppState) => Promise<boolean> {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            if (!currentstate.user.currentUser) {
                userMessageModule().then((mod) => {
                    mod.loginActions.notifyRequireLogin(elt, i18n, session?.id)(dispatch, getState);
                });
                return Promise.resolve(true);
            }
            if (!currentstate.user.currentUser.detail.id || !currentstate.user.currentUser.detail.isRegistered) {
                userMessageModule().then((mod) => {
                    mod.messageRegistrationActions.notifyRequireRegistration(elt, i18n, session?.id)(dispatch, getState);
                });
                return Promise.resolve(true);
            }

            if (session?.forKinds?.length && 
                !currentstate?.user?.currentUser?.detail?.kinds?.some?.(k => session?.forKinds?.includes?.(k))) {
                userMessageModule().then((mod) => {
                    mod.messageRegistrationActions
                        .notifyRequirePersonKinds(elt, i18n)(dispatch);
                });
                return Promise.resolve(true);
            }

            return sessionActionQueue.bookmark(session.id)(dispatch, getState).then(() => true);
        };
    },

    evaluate(
        elt: HTMLElement,
        session: Entities.ISession,
        i18nHelper: Entities.i18nHelper
    ) : (dispatch, getState: () => States.IAppState) => void {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState() as States.IAppState;
            const surveyDefinition = getSessionSurvey(currentstate.event.data, session, currentstate.i18n);

            if (surveyDefinition) {
                if (!surveyDefinition.allowAnonymous) {
                    if (!currentstate.user.currentUser || !currentstate.user.currentUser.detail) {
                        userMessageModule().then((mod) => {
                            mod.loginActions.notifyRequireLogin(elt, i18nHelper, session?.id)(dispatch, getState);
                        });
                        return;
                    }
                    if (!currentstate.user.currentUser.detail.isRegistered) {
                        userMessageModule().then((mod) => {
                            mod.messageRegistrationActions.notifyRequireRegistration(elt, i18nHelper,
                                session?.id)(dispatch, getState);
                        });
                        return;
                    }
                }

                if (session?.forKinds?.length && 
                    !currentstate?.user?.currentUser?.detail?.kinds?.some?.(k => session?.forKinds?.includes?.(k))) {
                    userMessageModule().then((mod) => {
                        mod.messageRegistrationActions
                            .notifyRequirePersonKinds(elt, i18nHelper)(dispatch);
                    });
                    return;
                }

                surveysModule(dispatch, getState).then((surveyModule) => {
                    let sessionTitle;
                    if (i18nHelper?.translateBag) {
                        sessionTitle = i18nHelper.translateBag(session.title);
                    } else {
                        sessionTitle = "sessionevaluation.evaluate";
                    }
                    surveyModule.surveysActions.showSurveyAction(
                        i18nHelper,
                        sessionTitle,
                        surveyDefinition,
                        "Session",
                        session.id,
                        () => { }
                    )(dispatch, getState);
                });
            }
        };
    },
};

export function checkIfModerator(user: States.IAppUserState, session: Entities.ISession, givenId?: string) {
    let isModerator;
    const userId = givenId || user?.currentUser?.detail?.id;
    if (userId) {
        let isSessionModerator;
        const isGlobalModerator = user?.currentUser?.data?.moderators?.data?.some((m) => {
            return m.id === userId && m.isGlobalModerator;
        });
        if (!isGlobalModerator) {
            if (session?.moderators?.length) {
                isSessionModerator = session.moderators.some((m) => {
                    return (m as any).moderatorId === userId;
                });
            }
        }
        isModerator = isGlobalModerator || isSessionModerator;
    }
    return isModerator;
}

export function getSessionModeratorsAndSpeakers(user: States.IAppUserState,
    event: States.IEventState, session: Entities.ISession) {
    const moderators = [];
    if (user?.currentUser?.data?.moderators?.data?.length) {
        user.currentUser.data.moderators.data.map((m) => {
            if (m.isGlobalModerator) {
                moderators.push(m.id);
            }
            return true;
        });
    }
    if (session?.moderators?.length) {
        session.moderators.map((sm) => {
            if (!moderators.some((m) => m === sm.moderatorId)) {
                moderators.push(sm.moderatorId);
            }
            return true;
        });
    }
    if (session?.speakers?.length) {
        session.speakers.map((s) => {
            if (!moderators.some((m) => m === s.id)) {
                moderators.push(s.id);
            }
            return true;
        });
    }
    return moderators;
}

export interface IAvailability {
    activateFrom?: moment.Moment;
    isBefore?: boolean;
    isLocked?: boolean;
    deactivateAfter?: moment.Moment;
    isAfter?: boolean;
}

export function checkAvailability(
    session: Entities.ISession,
    behavior: {
        activateFromStartDate?: {
            enabled: string | boolean;
            tolerance?: string | number;
        },
        deactivateAfterEndDate?: {
            enabled: string | boolean;
            tolerance?: string | number;
        },
    },
    props: {
        event?: States.IEventState,
        i18n?: States.i18nState
    }
) {
    const newstate: IAvailability = {};
    computeSessionDates(session, props.event.detail, props.i18n);
    const timeslot = session && session.timeslots && session.timeslots[0];
    let activateFromStart = behavior?.activateFromStartDate?.enabled;
    if (typeof activateFromStart === "string") {
        activateFromStart = getPropertyValue(session, activateFromStart);
    }

    if (activateFromStart) {
        if (timeslot && timeslot.startDate) {
            let start = session.$startDate;
            let tolerance = behavior.activateFromStartDate.tolerance;
            if (typeof tolerance === "string") {
                tolerance = getPropertyValue(session, tolerance);
            }
            if (tolerance) {
                start = start.clone().add(-tolerance, 'minutes');
            }
            newstate.activateFrom = start;
            newstate.isBefore = moment().isBefore(start);
            newstate.isLocked = newstate.isBefore;
        } else {
            newstate.isLocked = true;
        }
    } else {
        newstate.isBefore = false;
    }

    let deactivateAfter = behavior?.deactivateAfterEndDate?.enabled;
    if (typeof deactivateAfter === "string") {
        deactivateAfter = getPropertyValue(session, deactivateAfter);
    }
    if (deactivateAfter) {
        if (timeslot && timeslot.endDate) {
            let end = session.$endDate;
            let tolerance = behavior.deactivateAfterEndDate.tolerance;
            if (typeof tolerance === "string") {
                tolerance = getPropertyValue(session, tolerance);
            }
            if (tolerance) {
                end = end.clone().add(tolerance, 'minutes');
            }
            newstate.deactivateAfter = end;
            newstate.isAfter = moment().isAfter(end);
            newstate.isLocked = newstate.activateFrom ? newstate.isBefore || newstate.isAfter : newstate.isAfter;
        } else {
            newstate.isLocked = true;
        }
    } else {
        newstate.isAfter = false;
    }

    return newstate;
}

export const sessionActionQueue = {
    register: (sessionid: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "session", "register", sessionid)
        );
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return register(dispatch, getState, sessionid).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    unregister: (sessionid: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "session", "unregister", sessionid)
        );
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return unregister(dispatch, getState, sessionid).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    bookmark: (sessionid: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "session", "bookmark", sessionid)
        );
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return bookmark(dispatch, getState, sessionid).then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    registerBatch: (sessionids: string[]) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, "session", "registerbatch")
        );
        return registerBatch(dispatch, getState, sessionids).then((msgs) => {
            if (msgs?.length) {
                msgs.forEach((msg) => {
                    actionQueue.enqueue(msg, dispatch, getState);
                });
            }
        });
    }
};
